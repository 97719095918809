'use client'

import { TooltipProvider } from '@canyon/ui/Tooltip'
import { ErrorBoundary, Provider } from '@rollbar/react'
import { Session } from 'next-auth'

import { rollbarConfig } from '@/lib/rollbar'
import { ApolloProvider } from '@/provider/Apollo'
import { AuthProvider } from '@/provider/Auth'
import { GlobalDialogsProvider } from '@/provider/GlobalDialogsProvider'
import { PostHogProvider } from '@/provider/Posthog'

// MSW is not properly supported for TurboPack (or NextJS v14+ in general)
if (!process.env.NEXT_PUBLIC_TURBO_ENABLED && process.env.NODE_ENV === 'development') {
  import('@/__dev__/mocks').then(({ initMocks }) => {
    initMocks()
  })
}

interface IProviders {
  children: React.ReactNode
  token: Session['token']
}

export const Providers = ({ children, token }: IProviders) => {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <AuthProvider>
          <PostHogProvider>
            <TooltipProvider delayDuration={400}>
              <ApolloProvider token={token}>
                <GlobalDialogsProvider>{children}</GlobalDialogsProvider>
              </ApolloProvider>
            </TooltipProvider>
          </PostHogProvider>
        </AuthProvider>
      </ErrorBoundary>
    </Provider>
  )
}
