import(/* webpackMode: "eager" */ "/vercel/path0/apps/main-app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["BrowserExtensionToaster"] */ "/vercel/path0/apps/main-app/src/components/BrowserExtensionToaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["makeApolloClient"] */ "/vercel/path0/apps/main-app/src/lib/apolloWrapper.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/main-app/src/provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview"] */ "/vercel/path0/apps/main-app/src/provider/Posthog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LocalhostController"] */ "/vercel/path0/node_modules/@vercel/toolbar/dist/next/localhost-controller.js");
;
import(/* webpackMode: "eager", webpackExports: ["UnmountController"] */ "/vercel/path0/node_modules/@vercel/toolbar/dist/next/unmount.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/vercel/path0/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/src/components/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/globals.css");
